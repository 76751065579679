<template>
  <div class="wrapper">
    <transition name="fade">
      <img
        v-if="danloaded"
        alt="Dan Rath Portrait Photo"
        class="dan"
        @load="danloaded = true"
        src="./assets/dan.jpg"
      />
    </transition>

    <transition name="fade">
      <img
        v-if="logoloaded"
        t="Dan Rath"
        class="logo"
        @load="logoloaded = true"
        src="./assets/logo.svg"
      />
    </transition>

    <transition name="fade">
      <main v-if="!loading" class="content">
        <section class="intro indent">
          <p>
            Dan Rath has enough parking ticket debt to cripple a small European
            country and he has watched his car get cubed by the government. He
            has been disrespected by his colleagues and is bruised and battered
            with nothing to lose. His low status means he has heightened
            cortisol production and is not equipped to take a gentle ribbing. He
            has a diverse set of interests including prime numbers.
          </p>
        </section>

        <section class="shows">
          <h2>Shows</h2>

          <div v-if="loading" class="indent"></div>
          <div class="shows-list" v-else>
            <table v-if="groupsLength">
              <template v-for="(value, name, index) in groups" :key="name">
                <tr>
                  <td colspan="2">
                    <h3 :class="{ 'no-margin': !index }">{{ name }}</h3>
                  </td>
                </tr>

                <tr v-for="(show, index) in value" :key="index">
                  <td style="min-width: 80px" class="indent">
                    {{ renderShowDate(show) }}
                  </td>
                  <td width="35%" class="flex">
                    {{ show.venue }}
                  </td>
                  <td class="flex">{{ show.city }}</td>
                  <td align="right">
                    <a
                      class="btn"
                      :class="{ 'sold-out': show.soldOut }"
                      :href="show.ticketLink"
                      target="_blank"
                    >
                      {{ show.soldOut ? "Sold Out!" : "Tickets" }}
                    </a>
                  </td>
                </tr>
              </template>
            </table>
            <p v-else>No upcoming shows. Check back soon!</p>
          </div>
        </section>

        <section class="socials">
          <div class="podcast">
            <h2>Podcast</h2>
            <p>
              The News broken down by two confused neurotics: comedians Damien
              Power and Dan Rath. Previously Power to The People.
            </p>
            <a
              href="https://podcasts.apple.com/au/podcast/neurotic-news/id958430808"
              class="btn"
              >Listen</a
            >
          </div>
          <div class="newsletter">
            <h2>Newsletter</h2>
            <p>
              For all the latest news and upcoming events Subscribe to Dan
              Rath's mailing list.
            </p>
            <a href="http://eepurl.com/hBt0aj" target="_blank" class="btn"
              >Subscribe</a
            >
          </div>
        </section>
      </main>
    </transition>
  </div>

  <footer>
    <div>
      <h2>Contact</h2>
      <div class="contact-details">
        Jono Mastrippolito &nbsp;&nbsp; 0400 940 685 &nbsp;&nbsp;
        <a href="mailto:jono@thejunkyard.com.au">jono@thejunkyard.com.au</a>
      </div>
    </div>
    <div class="links">
      <div class="social-icons">
        <a href="https://twitter.com/drathcomics"
          ><img class="social-icon" src="./assets/twitter.png"
        /></a>
      </div>
      <a href="http://thejunkyard.com.au" target="_blank"
        ><img class="the-junkyard" src="./assets/the-junkyard.png"
      /></a>
    </div>
  </footer>
</template>

<script>
import dayjs from "dayjs";

const endpoint =
  "https://api-us-west-2.graphcms.com/v2/ckpm3eilgglw001xshqr68z8r/master";
const query = `?query={
  liveShows {
    city
    venue
    ticketLink
    startDate
    soldOut
    endDate
    id
  }
}
`;
export default {
  name: "App",

  data() {
    return {
      logoloaded: false,
      danloaded: false,
      loading: true,
      groups: {},
    };
  },

  methods: {
    renderShowDate(show) {
      const startDate = dayjs(show.startDate);
      const endDate = dayjs(show.endDate);

      let date = dayjs(show.startDate).format("MMM D");

      if (show.startDate !== show.endDate) {
        if (startDate.get("month") === endDate.get("month"))
          date += ` - ${endDate.format("DD")}`;
        else date += ` - ${endDate.format("MMM DD")}`;
      }

      return date;
    },
    formatDate(date, dateFormat) {
      return dayjs(date).format(dateFormat);
    },
  },

  computed: {
    groupsLength() {
      return Object.values(this.groups).length;
    },
  },
  mounted() {
    window.addEventListener('load', (event) => {
      this.logoloaded = true;
      this.danloaded = true;
    })
  },

  beforeMount() {
    fetch(endpoint + query).then((response) => {
      response.json().then((response) => {
        this.loading = false;
        const shows = response.data.liveShows.filter(
          (show) => dayjs(show.endDate).endOf("day") > dayjs()
        );

        shows.sort(function(a, b) {
          return new Date(a.endDate) - new Date(b.endDate);
        });

        shows.forEach((show) => {
          const startDate = dayjs(show.startDate);
          const endDate = dayjs(show.endDate);

          show.group = `${startDate.format("MMM YYYY")}`;
          if (startDate.get("month") !== endDate.get("month"))
            show.group = `${startDate.format("MMM")} - ${endDate.format(
              "MMM YYYY"
            )}`;
        });

        shows.forEach((show) => {
          if (!this.groups[show.group]) this.groups[show.group] = [];
          this.groups[show.group].push(show);
        });
      });
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap");

:root {
  --pink: #fea4be;
  --blue: #a4cdfe;
  --width: 1440px;
  --gutter: 44px;
  --section-gutter: 84px;
  --footer-height: 70px;

  @media screen and (max-width: 1023px) {
    --gutter: 32px;
    --section-gutter: 64px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

body {
  background: var(--pink);
  margin: 0;
}

a {
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

* {
  box-sizing: border-box;
}

p {
  margin-top: 0;
}

.social-icon {
  height: 33px;
  width: 33px;
}

.social-icons {
  display: flex;
  align-items: center;
  > * {
    margin-left: 8px;
  }
}

.the-junkyard {
  height: 35px;
  margin-right: 0;
}

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: white;
  font-weight: bold;
  line-height: 1.325;
}

.logo {
  width: 434px;
  margin-top: var(--gutter);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  max-width: 70vw;
}

.wrapper {
  &:before {
    content: "";
    width: 50%;
  }
  display: flex;
  margin: 0 auto;
  width: var(--width);
  max-width: 100%;
  position: relative;

  min-height: calc(100vh - var(--footer-height));

  @media screen and (max-width: 1023px) {
    display: block;
  }
}

.intro {
  font-size: 17px;
  padding-top: var(--gutter);
  @media screen and (min-width: 1024px) {
    padding-top: calc(64px + 24px + var(--gutter) * 2);

    max-width: 45ch;
  }
}

.indent {
  @media screen and (min-width: 1024px) {
    padding-left: 22px !important;
  }
}

.dan {
  width: 50vw;
  height: 100%;

  object-fit: cover;
  object-position: top right;
  top: 0;
  right: 50%;
  position: absolute;

  @media screen and (max-width: 1023px) {
    position: relative;
    width: 100%;
    height: auto;
    top: auto;
    right: auto;
    max-height: 50vh;
  }

  @media screen and (max-width: 639px) {
    max-height: 60vh;
  }
}

.content {
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
  padding: 0 var(--gutter);
}

.socials {
  > * {
    margin-bottom: var(--section-gutter);
  }
  @media screen and (min-width: 640px) {
    display: flex;
    justify-content: space-between;
    > * {
      width: calc(50% - var(--gutter));
      margin: 0;
    }
  }
}

section {
  margin-bottom: var(--section-gutter);
}

h2 {
  &:after {
    content: "";
    width: 100%;
    bottom: 0;
    background: var(--blue);
    height: 10px;
    display: block;
    position: absolute;
    opacity: 0.75;
  }
  display: inline-block;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 22px;
  position: relative;
  margin: 0;
  margin-bottom: 24px;
}

h3 {
  margin: 0;
  font-size: 17px;
  margin-top: 24px;
  &.no-margin {
    margin-top: 0;
  }
}

footer {
  height: var(--footer-height);

  padding: 0 var(--gutter);
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 24px;

  @media screen and (min-width: 640px) {
    display: flex;
    > div {
      display: flex;
      align-items: center;
      > * {
        margin-right: 24px;
      }
    }
  }
  h2 {
    margin: 0;
    margin-right: 24px;
  }

  @media screen and (max-width: 639px) {
    h2 {
      margin-bottom: 24px;
    }
    .links {
      display: flex;
      justify-content: space-between;
      margin-top: var(--gutter);
      padding-bottom: var(--gutter);
    }
    .social-icons a {
      margin: 0;
    }
  }
}

.btn {
  display: inline-flex;
  height: 36px;
  border: 2px var(--blue) solid;
  align-items: center;
  line-height: 1;
  padding: 0 12px;
  white-space: nowrap;

  &:hover {
    background: var(--blue);
    text-decoration: none;
  }

  &.sold-out {
    text-decoration: line-through;
  }
}

table {
  width: 100%;
  td {
    padding: 0 0 22px 22px;
    &:first-child {
      padding-left: 0;
    }
  }
  @media screen and (min-width: 640px) {
    tr:last-child td {
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 639px) {
    td {
      display: block;
      text-align: left;
      width: 100%;
    }

    h3 {
      margin-top: 36px;
    }
    tr {
      display: flex;
      flex-wrap: wrap;

      &:not([colspan]) {
        margin-bottom: 12px;
      }

      td {
        width: 100%;

        &.flex {
          width: 50%;
          padding-left: 0;
          padding-right: var(--gutter);
        }

        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
